import { Config, ConfigItem } from './types';

const configData: ConfigItem[] = [
  // ---------------dev-----------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'localhost',
    apiHost: 'https://dev.api.offer.pragma.info',

    coreURL: 'https://dev.core.pragma.info/'
  },
  {
    companyName: 'PragmaCore',
    appHost: 'dev.offer.pragma.info',
    apiHost: 'https://dev.api.offer.pragma.info',

    coreURL: 'https://dev.core.pragma.info/'
  },

  // ---------------prod------------------------------

  {
    companyName: 'PragmaCore',
    appHost: 'offer.pragma.info',
    apiHost: 'https://api.offer.pragma.info'
  },
  {
    companyName: 'ООО «Автоматизация Производств»',
    appHost: 'ap.offer.pragma.info',
    apiHost: 'https://ap.api.offer.pragma.info'
  },
  {
    companyName: 'Incepta Group',
    appHost: 'ig.offer.pragma.info',
    apiHost: 'https://ig.api.offer.pragma.info'
  },
  {
    companyName: 'Capital Group',
    appHost: 'cg.offer.pragma.info',
    apiHost: 'https://cg.api.offer.pragma.info'
  },
  {
    companyName: 'ФГКУ Росгранстрой',
    appHost: 'rgs.offer.pragma.info',
    apiHost: 'https://rgs.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «Стройиндустрия»',
    appHost: 'si.offer.pragma.info',
    apiHost: 'https://si.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «ЭСК «Энергомост»',
    appHost: 'energomost.offer.pragma.info',
    apiHost: 'https://energomost.api.offer.pragma.info'
  },
  {
    companyName: 'ГЕО',
    appHost: 'geo.offer.pragma.info',
    apiHost: 'https://geo.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «СУ-555»',
    appHost: 'su555.offer.pragma.info',
    apiHost: 'https://su555.api.offer.pragma.info'
  },
  {
    companyName: 'ООО ГК «СУ-555»',
    appHost: 'gcsu-555.offer.pragma.info',
    apiHost: 'https://gcsu-555.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «Рустэко»',
    appHost: 'rusteko.offer.pragma.info',
    apiHost: 'https://rusteko.api.offer.pragma.info'
  },
  {
    companyName: 'РЖД',
    appHost: 'rzd.offer.pragma.info',
    apiHost: 'https://rzd.api.offer.pragma.info'
  },
  {
    companyName: 'ООО ПО «Квант»',
    appHost: 'kvant.offer.pragma.info',
    apiHost: 'https://kvant.api.offer.pragma.info'
  },
  {
    companyName: 'АО «КРНО»',
    appHost: 'krno.offer.pragma.info',
    apiHost: 'https://krno.api.offer.pragma.info'
  },
  {
    companyName: 'АО «Оргэнергострой»',
    appHost: 'oes.offer.pragma.info',
    apiHost: 'https://oes.api.offer.pragma.info'
  },
  {
    companyName: 'АО «СК «Мост»',
    appHost: 'most.offer.pragma.info',
    apiHost: 'https://most.api.offer.pragma.info'
  },

  {
    companyName: 'АО «ГК «ЕКС»',
    appHost: 'eks.offer.pragma.info',
    apiHost: 'https://eks.api.offer.pragma.info'
  },
  {
    companyName: 'ФБУ «РосСтройКонтроль»',
    appHost: 'rsk.offer.pragma.info',
    apiHost: 'https://rsk.api.offer.pragma.info'
  },
  {
    companyName: 'Черное море',
    appHost: 'blacksea.offer.pragma.info',
    apiHost: 'https://blacksea.api.offer.pragma.info'
  },
  {
    companyName: 'Группа «Самолет»',
    appHost: 'samolet.offer.pragma.info',
    apiHost: 'https://samolet.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «Пелискер»',
    appHost: 'pelisker.offer.pragma.info',
    apiHost: 'https://pelisker.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «ЭЗОИС-Урал»',
    appHost: 'ezois-ural.offer.pragma.info',
    apiHost: 'https://ezois-ural.api.offer.pragma.info'
  },
  {
    companyName: 'ООО «СОЮЗДОНСТРОЙ»',
    appHost: 'soyuzdonstroy.offer.pragma.info',
    apiHost: 'https://soyuzdonstroy.api.offer.pragma.info'
  },
  {
    companyName: 'АО «ГАЗПРОМ ШЕЛЬФПРОЕКТ»',
    appHost: 'gsp.offer.pragma.info',
    apiHost: 'https://gsp.api.offer.pragma.info'
  },
  {
    companyName: 'ПСМ',
    appHost: 'psm.offer.pragma.info',
    apiHost: 'https://psm.api.offer.pragma.info'
  },
  {
    companyName: 'ВГТУ',
    appHost: 'vgtu.offer.pragma.info',
    apiHost: 'https://vgtu.api.offer.pragma.info'
  },
  {
    companyName: 'ТГАСУ',
    appHost: 'tgasu.offer.pragma.info',
    apiHost: 'https://tgasu.api.offer.pragma.info'
  }
];

const currentConfig = configData.find((it) => {
  return document.location.hostname === it.appHost;
});

export const config: Config = {
  coreURL: currentConfig?.coreURL ?? 'https://core.pragma.info/',
  appHost: currentConfig?.appHost ?? 'localhost',
  apiHost: currentConfig?.apiHost ?? 'https://api.offer.pragma.info',
  companyName: currentConfig?.companyName ?? 'PragmaCore'
};
